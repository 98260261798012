import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import Prismic from 'prismic-javascript';
import { APIENDPOINT } from '../config/constant';

class SliceStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: {},
    };
  }

  componentWillMount() {
    const apiEndpoint = APIENDPOINT;
    const { slice } = this.props;
    const ids = slice.items.map(item => (item.staff.id));
    Prismic.api(apiEndpoint).then((api) => {
      api.getByIDs(ids).then((response) => {
        const staff = {};
        response.results.forEach((result) => {
          staff[result.id] = result;
        });
        this.setState({ staff });
      });
    });
  }

  render() {
    const { slice } = this.props;
    const { staff } = this.state;
    return (
      <section className="sliceStaff">
        <Container>
          <Row>
            {slice.items.map(item => (
              <Col key={item.staff.id}>
                <div className="bio">
                  {staff[item.staff.id] !== undefined
                    ? (
                      <div>
                        <img
                          src={staff[item.staff.id].data.headshot.url}
                          alt={RichText.asText(staff[item.staff.id].data.name)}
                        />
                        <h3>{RichText.asText(staff[item.staff.id].data.name)}</h3>
                        {RichText.render(staff[item.staff.id].data.bio)}
                      </div>
                    ) : null
                  }
                </div>
              </Col>
            ))}
          </Row>
        </Container>
        <hr />
      </section>
    );
  }
}
SliceStaff.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceStaff;
