import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import slugify from 'slugify';
import ScrollableAnchor from 'react-scrollable-anchor';

import './css/main.css';
import SliceImage from './Slices/Image';
import SliceThreeColumns from './Slices/ThreeColumns';
import SliceStaff from './Slices/Staff';
import SliceContact from './Slices/Contact';
import { APIENDPOINT } from './config/constant';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: '',
      siteName: '',
      slices: [],
    };
  }

  componentWillMount() {
    const apiEndpoint = APIENDPOINT;
    Prismic.api(apiEndpoint).then((api) => {
      api.query(Prismic.Predicates.at('document.type', 'home')).then((response) => {
        if (response) {
          // console.log(response.results[0]);
          this.setState({
            logo: response.results[0].data.logo.url,
            siteName: RichText.asText(response.results[0].data.company_name),
            slices: response.results[0].data.body,
          });
        }
      });
    });
  }

  renderSlices() {
    // console.log(this.state.slices);
    const { slices } = this.state;
    return (
      <div>
        {slices.map((slice, i) => {
          const scrollId = `${slugify(RichText.asText(slice.primary.section_title))}`;
          switch (slice.slice_type) {
            case 'image':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ScrollableAnchor key={i} id={scrollId}>
                  <div>
                    <SliceImage slice={slice} />
                  </div>
                </ScrollableAnchor>
              );
            case '3_columns':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ScrollableAnchor key={i} id={scrollId}>
                  <div>
                    <SliceThreeColumns slice={slice} />
                  </div>
                </ScrollableAnchor>
              );
            case 'staff':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ScrollableAnchor key={i} id={scrollId}>
                  <div>
                    <SliceStaff slice={slice} />
                  </div>
                </ScrollableAnchor>
              );
            case 'contact_page':
              return (
                // eslint-disable-next-line react/no-array-index-key
                <ScrollableAnchor key={i} id={scrollId}>
                  <div>
                    <SliceContact slice={slice} />
                  </div>
                </ScrollableAnchor>
              );
            default:
              // console.log(slice.slice_type);
              return null;
          }
        })}
      </div>
    );
  }

  render() {
    const nav = [];
    const { slices, logo, siteName } = this.state;
    slices.forEach((slice) => {
      if (RichText.asText(slice.primary.section_title) !== '') {
        nav.push({
          label: RichText.asText(slice.primary.section_title),
          href: `#${slugify(RichText.asText(slice.primary.section_title))}`,
        });
      }
    });
    return (
      <div className="App">
        <Container>
          <Navbar expand="lg" sticky="top">
            <Navbar.Brand href="/">
              <img src={logo} alt={siteName} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                {nav.map((item, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Nav.Link key={i} href={item.href}>
                    {item.label}
                  </Nav.Link>
                ))}
              </Nav>
              <Navbar.Text>
                718-797-4636
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
        </Container>
        {this.renderSlices()}
      </div>
    );
  }
}

export default App;
