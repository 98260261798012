import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';


function SliceContact(props) {
  const { slice } = props;
  return (
    <section className="sliceContact">
      <Container>
        <Row>
          <Col lg={4} className="address">
            <h2>{RichText.asText(slice.primary.section_title)}</h2>
            {RichText.render(slice.primary.address)}
          </Col>
          <Col>
            <form
              className="contact-form"
              name="contact-form"
              method="POST"
              data-netlify="true"
            >
              <label htmlFor="name">
                Your Name
                <input type="text" name="name" id="name" />
              </label>
              <label htmlFor="replyto">
                Your Email
                <input id="replyto" type="email" name="_replyto" />
              </label>
              <label htmlFor="message">
                Message
                <textarea name="message" id="message" />
              </label>
              <input type="submit" value="Send" />
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
SliceContact.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
  }).isRequired,
};
export default SliceContact;
