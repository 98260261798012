import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';

function SliceThreeColumns(props) {
  const { slice } = props;
  return (
    <section className="sliceThreeColumns">
      <Container>
        <Row>
          {slice.items.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col key={i} md={4}>
              <h2>
                <span className={`fas fa-${item.icon_class}`} />
                {RichText.asText(item.title)}
              </h2>
              {RichText.render(item.description)}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
SliceThreeColumns.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default SliceThreeColumns;
