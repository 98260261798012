import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RichText } from 'prismic-reactjs';
import PropTypes from 'prop-types';

function SliceImage(props) {
  const { slice } = props;
  const coverStyle = {
    backgroundImage: `url("${slice.primary.cover_image.url}")`,
  };
  return (
    <section style={coverStyle} className="sliceImage">
      <Container>
        <Row>
          <Col>
            <h1>{RichText.asText(slice.primary.title)}</h1>
            <h2>{RichText.asText(slice.primary.sub_title)}</h2>
            <div className="linkDiv">
              <a href={slice.primary.action_url.url} className="linkAsButton">
                {RichText.asText(slice.primary.action)}
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
SliceImage.propTypes = {
  slice: PropTypes.shape({
    primary: PropTypes.object,
  }).isRequired,
};

export default SliceImage;
